import React from "react";

import { PasswordForgetForm } from "../components/Auth/PasswordForget";

import styled from "styled-components";

const SignElements = styled.div`
  padding: 4%;
  font-size: 20px;
`;

const PasswordForgetPage = () => (
  <div>
    <SignElements>
      <PasswordForgetForm />
    </SignElements>
  </div>
);

export default PasswordForgetPage;
